@import '../variables.scss';

.Update {
    padding: 15px;
    flex: 1;
    text-align: center;

    h1 {
        font-size: 26px;
        text-transform: uppercase;
        margin: 0 0 1rem;
    }

    button {
        border-radius: 5px;
        border: none;
        padding: 1rem;
        margin: 1.5rem 0 0;
        width: 100%;
        display: block;
        background-color: $secondary;
        color: $primary;
        font-size: 1rem;
        &.Alt {
            background-color: transparent;
            color: $primary;
            margin: 0.5rem 0 0;
        }
    }
}