@import '../../variables.scss';

.ShiftView {
    padding: 25px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .ShiftCompleted {
        padding: 15px;
        border-radius: 5px;
        margin: 1.5rem 0 0;
        color: #155724;
        background-color: #d4edda;
        border: solid 1px #c3e6cb;
    }

    &.is--busy {
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    .Back {
        font-weight: 600;
        font-size: 18px;
        text-decoration: none;
        display: block;
        span {
            background-color: $primary;
            color: #FFFFFF;
            width: 32px;
            height: 32px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 35%;
            vertical-align: 0;
            margin: 0 10px 0 0;
            svg {
                width: 12px;
                height: 12px;
                transform: scale(-1);
            }
        }
    }
    
    .LocationError {
        margin: 1.5rem 0 0;
        padding: 15px;
        color: #721c24;
        background-color: #f8d7da;
        border: solid 1px #f5c6cb;
        border-radius: 5px;
    }

    .LocationIssue {
        border-radius: 5px;
        border: none;
        padding: 1rem;
        margin: 1rem 0 0;
        width: 100%;
        display: block;
        background-color: $primary;
        color: #FFFFFF;
        font-size: 1rem;
        &:disabled {
            opacity: 0.5;
        }
    }

    &Detail {
        border-radius: 5px;
        background-color: $grey;
        padding: 15px;
        margin: 1.5rem 0 0;

        h2 {
            font-size: 1rem;
            color: $dark-grey;
            font-weight: 600;
            margin: 0 0 1rem;
        }
        h1 {
            font-size: 1rem;
            font-weight: 600;
            margin: 0;
            color: $primary;
        }
        p {
            span {
                & + span {
                    &:before {
                        content: '|';
                        margin: 0 7px 0 4px;
                        color: $dark-grey;
                    }
                }
            }
        }
        .ShiftLocation {
            & + .ShiftLocation {
                margin-top: -1rem;
            }
        }

        .ShiftAction {
            border-radius: 5px;
            border: none;
            padding: 1rem;
            margin: 1rem 0 0;
            width: 100%;
            display: block;
            background-color: $secondary;
            color: $primary;
            font-size: 1rem;
            &:disabled {
                opacity: 0.5;
            }
            & + p {
                margin-top: 1.5rem;
            }
        }
        .ShiftLink {
            border-radius: 5px;
            border: none;
            padding: 1rem;
            margin: 0;
            width: 100%;
            display: block;
            background-color: transparent;
            color: $primary;
            font-size: 1rem;
            &:disabled {
                opacity: 0.5;
            }
        }
    }
}