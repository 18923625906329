@import '../../variables';

.Modal {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000000, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 30px;
    z-index: 1000;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
    &Body {
        background-color: #FFFFFF;
        border-radius: 5px;
        padding: 15px;
        text-align: center;
        position: relative;

        svg {
            position: absolute;
            right: 15px;
            top: 19px;
            color: $primary;
        }

        .ModalTitle {
            color: $primary;
            font-size: 18px;
            text-align: left;
            padding-right: 30px;
        }

        p:last-child {
            margin-bottom: 0;
        }

        .text-left {
            p {
                text-align: left;
            }
        }

        .PrimaryButton {
            border-radius: 5px;
            border: none;
            padding: 1rem;
            margin: 1.5rem 0 0;
            width: 100%;
            display: block;
            background-color: $secondary;
            color: $primary;
            font-size: 1rem;
            &:disabled {
                opacity: 0.5;
            }
        }
        .LinkButton {
            border-radius: 5px;
            border: none;
            padding: 1rem;
            margin: 0;
            width: 100%;
            display: block;
            background-color: transparent;
            color: $primary;
            font-size: 1rem;
            &:disabled {
                opacity: 0.5;
            }
        }
    }
}