@import '../variables.scss';

.Resources {
    padding: 25px 25px 0;
    display: flex;
    overflow: auto;
    flex-direction: column;

    &.is--busy {
        height: 100%;
        position: relative;
        .Busy {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    h1 {
        font-size: 26px;
        text-transform: uppercase;
        margin: 0 0 1rem;
    }

    &List {
        flex: 1;
        margin: 0 0 25px;
        h2 {
            font-size: 22px;
            margin: 1rem 0;
            color: $dark-grey;
            font-weight: 600;
            &:first-child {
                margin-top: 0;
            }
        }
        &Item {
            background-color: $grey;
            border-radius: 5px;
            border: none;
            display: flex;
            width: 100%;
            align-items: center;
            padding: 1rem;
            margin: 1rem 0 0 0;
            strong {
                display: block;
                color: $primary;
                font-size: 16px;
                margin: 0;
                text-align: left;
            }
            > span {
                background-color: $primary;
                flex-shrink: 0;
                color: #FFFFFF;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 35%;
                margin: 0 0 0 auto;
                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
}