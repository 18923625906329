@import '../variables.scss';

.Setup {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 25px;

    h1 {
        font-size: 26px;
        margin: 0 0 1rem 0;
        text-transform: uppercase;
    }

    h2 {
        font-size: 22px;
        font-weight: 600;
        margin: 1rem 0;
    }

    .LocationError {
        background-color: #f8d7da;
        border: solid 1px #f5c6cb;
        border-radius: 5px;
        color: #721c24;
        margin: 0;
        padding: 15px;

        small {
            display: block;
            font-size: 14px;
            margin-top: 0.5rem;
        }
    }

    .PermissionButton {
        background-color: $primary;
        border: none;
        border-radius: 5px;
        color: #FFFFFF;
        display: block;
        font-size: 1rem;
        margin: 0;
        padding: 1rem;
        width: 100%;
        &:disabled {
            background-color: green;
        }
    }
    .Button {
        background-color: $secondary;
        border: none;
        border-radius: 5px;
        color: $primary;
        display: block;
        font-size: 1rem;
        margin: 0;
        margin: 1.5rem 0 0;
        padding: 1rem;
        width: 100%;
        &:disabled {
            opacity: 0.5;
        }
    }
}