@import '../variables.scss';

@import url("https://use.typekit.net/ffx8iiw.css");

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: "mundial", sans-serif;
    font-size: 16px;
    line-height: 1.2;
    overscroll-behavior-y: contain;

    @media (min-width: 1200px) {
        &:before {
            content: 'This app is only supported on mobile devices';
            position: absolute;
            z-index: 10000;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: $primary;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFFFFF;
            font-size: 2rem;
        }
    }
}

strong, b {
    font-weight: 600;
}

*, ::before, ::after {
    box-sizing: border-box;
}

p {
    margin: 0 0 1rem;
}

#root {
    display: flex;
    height: 100%;
    position: relative;
}