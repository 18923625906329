@import '../variables.scss';

.Debug {
    padding: 15px;

    button {
        border-radius: 5px;
        border: none;
        padding: 1rem;
        margin: 1.5rem 0 0;
        width: 100%;
        display: block;
        background-color: $secondary;
        color: $primary;
        font-size: 1rem;
        &:disabled {
            opacity: 0.5;
        }
        &.Alt {
            background-color: transparent;
            color: $primary;
            margin: 0.5rem 0 0;
        }
        &.Alt2 {
            background-color: $primary;
            color: $secondary;
            margin: 0.5rem 0 0;

            .Busy {
                div {
                    background-color: $secondary;
                }
            }
        }
    }
}