@import '../variables.scss';

.Profile {
    padding: 25px;
    display: flex;
    flex-direction: column;

    &.is--busy {
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    h1 {
        font-size: 26px;
        text-transform: uppercase;
        margin: 0;
    }

    form {
        & > label {
            display: block;
            margin: 15px 0 5px;
            color: $dark-grey;
        }
        input:not([type="checkbox"]), select {
            display: block;
            border-radius: 5px;
            border: none;
            padding: 15px;
            width: 100%;
            font-size: 1rem;
            border: solid 1px $grey;
            background-color: $grey;
            &:disabled {
                opacity: 0.5;
            }
            &.has-error {
                border-color: red;
            }
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            li {
                width: 50%;
                position: relative;
                margin-top: 10px;
                input {
                    position: absolute;
                    left: -100px;
                    opacity: 0;
                    &:checked {
                        & + label {
                            &:after {
                                content: '';
                            }
                        }
                    }
                    &:disabled {
                        & + label {
                            opacity: 0.5;
                        }
                    }
                }
                label {
                    line-height: 32px;
                    padding-left: 45px;
                    font-size: 14px;
                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 32px;
                        height: 32px;
                        border: solid 1px $grey;
                    }
                    &:after {
                        position: absolute;
                        left: 7px;
                        top: 7px;
                        width: 18px;
                        height: 18px;
                        background-color: $primary;
                    }
                }
            }
        }


        button:not([type="button"]) {
            border-radius: 5px;
            border: none;
            padding: 1rem;
            margin: 1rem 0 0;
            width: 100%;
            display: block;
            background-color: $secondary;
            color: $primary;
            font-size: 1rem;
            &:disabled {
                opacity: 0.5;
            }
            & + p {
                margin-top: 1.5rem;
            }
        }
        button[type="button"] {
            border-radius: 5px;
            border: none;
            padding: 1rem;
            margin: 0;
            width: 100%;
            display: block;
            background-color: transparent;
            color: red;
            font-size: 1rem;
            &:disabled {
                opacity: 0.5;
            }
        }
    }
}